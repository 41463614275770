import React, {FC, Suspense, lazy, useMemo, useEffect, useRef, useState} from 'react';
import useCompetitions from 'hooks/useCompetitions';
import useHomepageWidgets from 'hooks/useHomepageWidgets';
import usePolling from 'hooks/usePolling';
import useScoreboardPanels from 'hooks/useScoreboardPanels';
import useScoresWidgets from 'hooks/useScoresWidgets';
import useStandingsWidgets from 'hooks/useStandingsWidgets';
import useTopMatchesWidgets from 'hooks/useTopMatchesWidgets';
import useTvProgramWidgets from 'hooks/useTvProgramWidgets';
import MatchcenterHomepageWidget from 'views/MatchcenterHomepageWidget';
import MatchcenterScoreboardPanel from 'views/MatchcenterScoreboardPanel';
import MatchcenterScoresWidget from 'views/MatchcenterScoresWidget';
import MatchcenterStandingsWidget from 'views/MatchcenterStandingsWidget';
import MatchcenterTopMatchesWidget from 'views/MatchcenterTopMatchesWidget';
import MatchcenterTvProgramWidget from 'views/MatchcenterTvProgramWidget';
import getConfig from './getConfig';

const MatchcenterWidgetsRoot: FC = () => {
  const homepageWidgetRoots = useRef<{[id: string]: HTMLElement}>({});
  const topMatchesWidgetRoots = useRef<{[id: string]: HTMLElement}>({});
  const tvProgramWidgetRoots = useRef<{[id: string]: HTMLElement}>({});
  const scoreboardPanelsRoots = useRef<{[id: string]: HTMLElement}>({});
  const scoresWidgetsRoots = useRef<{[id: string]: HTMLElement}>({});
  const standingsWidgetsRoots = useRef<{[id: string]: HTMLElement}>({});

  const [, {getCompetitions}] = useCompetitions();
  const [homepageWidgetsState, {getHomepageWidgets}] = useHomepageWidgets();
  const {data: homepageWidgets} = homepageWidgetsState;
  const [topMatchesWidgetsState, {getTopMatchesWidgets}] = useTopMatchesWidgets();
  const {data: topMatchesWidgets} = topMatchesWidgetsState;
  const [tvProgramWidgetsState, {getTvProgramWidgets}] = useTvProgramWidgets();
  const {data: tvProgramWidgets} = tvProgramWidgetsState;
  const [scoreboardPanelsState, {getScoreboardPanels}] = useScoreboardPanels();
  const {data: scoreboardPanels} = scoreboardPanelsState;
  const [scoresWidgetsState, {getScoresWidgets}] = useScoresWidgets();
  const {data: scoresWidgets} = scoresWidgetsState;
  const [standingsWidgetsState, {getStandingsWidgets}] = useStandingsWidgets();
  const {data: standingsWidgets} = standingsWidgetsState;

  const {pollingRate} = getConfig();
  const {withPolling, clearAllPollings} = usePolling();

  const getPolledHomepageWidgets = withPolling(getHomepageWidgets, pollingRate);
  const getPolledTopMatchesWidgets = withPolling(getTopMatchesWidgets, pollingRate);
  const getPolledTvProgramWidgets = withPolling(getTvProgramWidgets, pollingRate);
  const getPolledScoreboardPanels = withPolling(getScoreboardPanels, pollingRate);
  const getPolledScoresWidgets = withPolling(getScoresWidgets, pollingRate);
  const getPolledStandingsWidgets = withPolling(getStandingsWidgets, pollingRate);

  useEffect(() => {
    const uniquePartial = new Date().getTime();

    /* collect widgets */
    const hpw: NodeListOf<HTMLElement> = document.querySelectorAll(
      '[data-matchcenter-homepage-group]'
    );
    Array.from(hpw).forEach((el, i) => {
      homepageWidgetRoots.current[`hpw-${uniquePartial}-${i}`] = el;
    });

    const tmw: NodeListOf<HTMLElement> = document.querySelectorAll(
      '[data-matchcenter-top-matches-group]'
    );
    Array.from(tmw).forEach((el, i) => {
      topMatchesWidgetRoots.current[`tmw-${uniquePartial}-${i}`] = el;
    });

    const tvw: NodeListOf<HTMLElement> = document.querySelectorAll(
      '[data-matchcenter-tvprogram-widget]'
    );
    Array.from(tvw).forEach((el, i) => {
      tvProgramWidgetRoots.current[`tvw-${uniquePartial}-${i}`] = el;
    });

    const scp: NodeListOf<HTMLElement> = document.querySelectorAll('[data-matchcenter-match-id]');
    Array.from(scp).forEach((el, i) => {
      scoreboardPanelsRoots.current[`scp-${uniquePartial}-${i}`] = el;
    });

    const scw: NodeListOf<HTMLElement> = document.querySelectorAll('[data-matchcenter-match-ids]');
    Array.from(scw).forEach((el, i) => {
      scoresWidgetsRoots.current[`scw-${uniquePartial}-${i}`] = el;
    });

    const stw: NodeListOf<HTMLElement> = document.querySelectorAll('[data-matchcenter-season-id]');
    Array.from(stw).forEach((el, i) => {
      standingsWidgetsRoots.current[`stw-${uniquePartial}-${i}`] = el;
    });

    /* API calls */

    if (hpw.length || tmw.length || tvw.length || scp.length || scw.length || stw.length) {
      getCompetitions();
    }

    if (Object.values(homepageWidgetRoots.current).length) {
      getPolledHomepageWidgets(
        Object.entries(homepageWidgetRoots.current).map(([id, el]) => ({
          widgetId: id,
          groupId: el.dataset.matchcenterHomepageGroup,
          betGroupId: el.dataset.matchcenterHomepageBetGroup,
          isMobile: el.dataset.matchcenterHomepageIsMobile === 'true',
        }))
      );
    }

    if (Object.values(topMatchesWidgetRoots.current).length) {
      getPolledTopMatchesWidgets(
        Object.entries(topMatchesWidgetRoots.current).map(([id, el]) => ({
          widgetId: id,
          groupId: el.dataset.matchcenterTopMatchesGroup,
        }))
      );
    }

    if (Object.values(tvProgramWidgetRoots.current).length) {
      getPolledTvProgramWidgets(
        Object.entries(tvProgramWidgetRoots.current).map(([id, el]) => ({
          widgetId: id,
        }))
      );
    }

    if (Object.values(scoreboardPanelsRoots.current).length) {
      getPolledScoreboardPanels(
        Object.entries(scoreboardPanelsRoots.current).map(([id, el]) => ({
          widgetId: id,
          matchId: el.dataset.matchcenterMatchId,
        }))
      );
    }

    if (Object.values(scoresWidgetsRoots.current).length) {
      getPolledScoresWidgets(
        Object.entries(scoresWidgetsRoots.current).map(([id, el]) => ({
          widgetId: id,
          header: el.dataset.matchcenterHeader,
          matchIds: el.dataset.matchcenterMatchIds?.split(',') ?? [],
        }))
      );
    }

    if (Object.values(standingsWidgetsRoots.current).length) {
      getPolledStandingsWidgets(
        Object.entries(standingsWidgetsRoots.current).map(([id, el]) => {
          const [start, end] =
            el.dataset.matchcenterRange?.split('-').map(part => part.trim()) ?? [];

          return {
            widgetId: id,
            header: el.dataset.matchcenterHeader,
            isCarousel: el.dataset.matchcenterSeasonCarousel === 'true',
            seasonId: el.dataset.matchcenterSeasonId,
            groupId: el.dataset.matchcenterGroupId,
            start,
            end,
          };
        })
      );
    }

    return () => {
      getPolledHomepageWidgets.cancel();
      getPolledTopMatchesWidgets.cancel();
      getPolledTvProgramWidgets.cancel();
      getPolledScoreboardPanels.cancel();
      getPolledScoresWidgets.cancel();
      getPolledStandingsWidgets.cancel();
    };
  }, []);

const now = useMemo(() => {
  const today = new Date();
  const hours = `0${today.getHours()}`.slice(-2);
  const minutes = `0${today.getMinutes()}`.slice(-2);
 return `${hours}:${minutes}`
}, [standingsWidgetsState])

  useEffect(() => clearAllPollings, []);

  return (
    <>
      {Object.entries(homepageWidgetRoots.current).map(
        ([id, root], index) =>
          homepageWidgets[id] && (
            <MatchcenterHomepageWidget
              key={id}
              root={root}
              index={index}
              isMobile={homepageWidgets[id].isMobile}
              matches={homepageWidgets[id].matches}
              tvData={homepageWidgets[id].tvProgramEvents}
              betData={Object.values(homepageWidgets[id].betData ?? {})}
              betProvider={homepageWidgets[id].betProvider}
            />
          )
      )}
      {Object.entries(topMatchesWidgetRoots.current).map(
        ([id, root]) =>
          topMatchesWidgets[id] && (
            <MatchcenterTopMatchesWidget
              key={id}
              root={root}
              matches={topMatchesWidgets[id].matches}
            />
          )
      )}
      {Object.entries(tvProgramWidgetRoots.current).map(
        ([id, root]) =>
          tvProgramWidgets[id] && (
            <MatchcenterTvProgramWidget
              key={id}
              root={root}
              tvData={tvProgramWidgets[id].tvProgramEvents}
            />
          )
      )}
      {Object.entries(scoreboardPanelsRoots.current).map(
        ([id, root]) =>
          scoreboardPanels[id] && (
            <MatchcenterScoreboardPanel key={id} root={root} match={scoreboardPanels[id]} />
          )
      )}
      {Object.entries(scoresWidgetsRoots.current).map(
        ([id, root]) =>
          scoresWidgets[id] && (
            <MatchcenterScoresWidget key={id} root={root} header={scoresWidgets[id].header} matches={scoresWidgets[id].matches} />
          )
      )}
      {Object.entries(standingsWidgetsRoots.current).map(
        ([id, root]) =>
          standingsWidgets[id] && (
            <MatchcenterStandingsWidget
              key={id}
              root={root}
              header={standingsWidgets[id].header}
              isCarousel={standingsWidgets[id].isCarousel}
              competitionName={standingsWidgets[id].competitionName}
              groups={standingsWidgets[id].groups}
              now={now}
            />
          )
      )}
    </>
  );
};

export default MatchcenterWidgetsRoot;
